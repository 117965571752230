import { apiClient } from '../index';


/**
 * [Regresa todos los tickets]
 * @return {[type]}          [tickets]
 */
function fetchByUserId() {
    return apiClient.get('/v1/tickets/user/');
  }

   /**
 * [Regresa el ticket by Id]
 * @return {[type]}          [ticket]
 */
function fetchTicket(id) {
  console.log('service');
  console.log(id);
    return apiClient.get('/v1/tickets/ticket/' + id);
  }

  /**
 * [Agrega un mensaje al hilo del ticket]
 * @return {[type]}          [mensaje]
 */
function addTicketMessage (id, msg) {
  return apiClient.post('/v1/tickets/addTicketMessage/', {
      ticket_id: id,
      message: msg
  });
}

/**
 * [cerrar ticket]
 * @return {[type]}          [ticket]
 */
function closeTicket (ticketId) {
  return apiClient.get('/v1/tickets/close/' + ticketId);
}

    /**
 * [Abre un nuevo ticket]
 * @return {[type]}          [ticket]
 */
function create(subject, msg) {
    return apiClient.post('/v1/tickets/openTicket/',  { subject, message:msg });
  }

  export default { fetchByUserId, fetchTicket, addTicketMessage, closeTicket, create };
