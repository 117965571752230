<template>
    <div>
      <v-row>
          <v-col cols="6" sm="6">
            <p class="title-user-scr"> Mis Tickets</p>         
          </v-col>
          <v-col cols="6" sm="6">
            <v-btn color="primary" small fab dark  :to="{ name: 'newticket' }" style="margin-inline-start:70%">
                  <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-col v-for="(item, i) in shownCards" :key="i" cols="12">
          <v-card color="white">
            <div class="d-flex flex-wrap justify-space-between">
              <v-list-item style="padding:10px">               
                <v-list-item-content style="margin-left: 0px; margin-right: 0px">
                  <v-list-item-title class="title-text-info">Ticket {{
                    item.id}}  
                    <span v-if="item.dateClosed" style="font-weight: 800; color:#f00">Cerrado </span>
                    <span v-else style="font-weight: 800;">abierto</span>
                  </v-list-item-title>
                
                  <v-list-item-title style="white-space: wrap;">
                   {{ item.subject }}</v-list-item-title>
                  <v-list-item-subtitle>  
                    <span style="font-size: 12px"> {{ $dayjs(item.dateOpened).format('lll') }} {{ item.id }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
             
                <v-list-item-action style="margin-left: 0px">                  
                    <div style="padding-top: 10px">
                        <v-btn  color="primary" small fab dark  :to="{ name: 'ticketview', params: { ticketId: item.id} }"  >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>                 
                    </div>                   
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-card>
        </v-col>
        <div class="text-center">
            <v-pagination v-model="page" :length="length"></v-pagination>
        </div>
    </div>
  </template>
  
  <script>
  
  import userTicketService from '@/services/users/tickets';
  
  export default {
    created() {
      this.getTickets();   
    },
    methods: {
      getTickets() {
        userTicketService.fetchByUserId().then((response) => {
          this.tickets = response.data;
          console.log(this.tickets);
        });
      },
    },
    computed: {
      userId() {
        console.log(this.$store.getters['session/userId']);
        return this.$store.getters['session/userId'];
        },  
      user() {
        console.log(this.$store.getters['session/user']);
        return this.$store.getters['session/user'];
      },
      imageDefaul() {
        return require('@/assets/images/avatar_dz.jpg');
      },
      length() {
        return Math.ceil(this.tickets.length / 10);
      },
      shownCards() {
        const { page, tickets } = this;
        const number = 10; // Numero de elementos a mostrar por página
        return tickets.slice((page - 1) * number, page * number);
      },
    },
    data() {
      return {   
        page: 1,  
        tickets: [],
      };
    },
  
  
  };
  </script>
  
  <style lang="scss" scoped>
  @import './src/assets/style.scss';
  </style>